import React, { useState } from 'react';
import axios from 'axios';

const ContactSection = () => {
  // State to manage form inputs
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    message: '',
  });

  // State to manage validation errors
  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validate form inputs
  const validateForm = () => {
    const newErrors = {};

    // Validate Full Name
    if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full Name is required';
    }

    // Validate Phone Number
    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = 'Phone Number is required';
    // } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
    } else if (formData.phoneNumber.length < 10 || formData.phoneNumber.length > 12) {
    newErrors.phoneNumber = 'Phone Number must be 10 digits';
    }

    // Validate Email
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }

    // Validate Message
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    try {
      // Send form data to the API
      const response = await axios.post('http://www.znhsolutions.com/submit', formData);

      // Handle success response
      if (response.status === 200) {
        alert('Form submitted successfully!');
        // Reset form
        setFormData({
          fullName: '',
          phoneNumber: '',
          email: '',
          message: '',
        });
        setErrors({});
      }
    } catch (error) {
      // Handle error
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form. Please try again.');
    }
  };

  return (
    <section className="coursl-sec py-4 mrg-tp mt-5" id="contactus">
      <div className="container">
        <div className="col-md-12">
          <h2 className="text-center">Get in Touch</h2>
          <div className="row mt-5">
            <div className="col-md-6">
              <img
                src={require('../assets/img/get-tch.png')}
                width="60%"
                alt="Logo"
                className="mbl-non"
                align="right"
              />
            </div>
            <div className="col-md-6">
              <div className="form-container">
                <form onSubmit={handleSubmit}>
                  <div>
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Full Name"
                      value={formData.fullName}
                      onChange={handleInputChange}
                      required
                    />
                    {errors.fullName && <p className="error">{errors.fullName}</p>}
                  </div>
                  <div>
                    <input
                      type="tel"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      required
                    />
                    {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}
                  </div>
                  <div>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>
                  <div>
                    <textarea
                      name="message"
                      placeholder="Type your message here...."
                      value={formData.message}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                    {errors.message && <p className="error">{errors.message}</p>}
                  </div>
                  <button type="submit" className="mt-3 button-nw">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className=" coursl-cont py-4 mrg-tp mt-2">
        <div className="container" align="center">
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-4 mbl-btm">
                        <div className="phone-box">
                            <div className="phone-icon">
                                <i className="fa-solid fa-phone"></i>
                            </div>
                            <div className="phone-text">
                                <span>Phone No:</span>
                                <strong>(+92) 345-0547860</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mbl-btm">
                        <div className="phone-box">
                            <div className="phone-icon">
                                <i className="fa-regular fa-envelope"></i>
                            </div>
                            <div className="phone-text">
                                <span>Email:</span>
                                <strong>info@znhsolutions.com</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mbl-btm">
                        <div className="phone-box">
                            <div className="phone-icon">
                                <i className="fa-solid fa-location-dot"></i>
                            </div>
                            <div className="phone-text">
                                <span>Office# 41, Khudadad <br/> <small>Heights E11 Islamabad, Pakistan</small></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </section>
    
  );
};

export default ContactSection;