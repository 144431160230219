import React from 'react';

const HeroSection = () => {
  return (
    <section className="text-center py-4 mrg-tp">
      <div className="container">
        <div className="col-md-12">
          <h2 className="fnt-stl">WE DESIGN, DEVELOP, AND <br /> SUPPORT WEBSITES AND <br /> WEB APPLICATIONS.</h2>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;