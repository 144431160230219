import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ServicesSection from './components/ServicesSection';
import AboutSection from './components/AboutSection';
import PortfolioSection from './components/PortfolioSection';
import ContactSection from './components/ContactSection';
import WhyUsSections from './components/WhyUsSections';
import BannerGetStarted from './components/BannerGetStarted';
import StatsSection from './components/StatsSection';
import Footer from './components/Footer';
// import Footer from './components/Footer';
import './assets/css/style.css';


function App() {
  return (
    <div >
      <Header />
      <HeroSection />
      <ServicesSection />
      <AboutSection />
      {/* <PortfolioSection /> */}
      <WhyUsSections/>
      <BannerGetStarted/>
      <StatsSection/>
      <ContactSection />
      <Footer />
    </div>
  );
}

export default App;
