import React from 'react';
import aboutImage from '../assets/img/Frame.png';

const AboutSection = () => {
  return (
    <section className="sec-3 py-4 mrg-tp mt-5" id="about_us">
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-6">
            <img src={aboutImage} width="80%" alt="Logo" className="mbl-tp" />
          </div>
          <div className="col-md-6">
            <h2>About us</h2>
            <span className="mt-5">At Znh Solutions, we are dedicated to driving innovation and excellence in the realm of software development. Our name, Znh, symbolizes our commitment to reaching new heights and exploring new horizons in the technology landscape.</span>
            <span>Founded by a team of passionate technologists, Znh Solutions is a dynamic house of software building technologies that thrives on creating cutting-edge solutions tailored to meet the unique needs of our clients. With a blend of creativity, technical expertise, and a customer-centric approach, we transform complex challenges into seamless, effective solutions.</span>
            <ul className="mt-2">
                        <li>Highly skilled team specializing in dynamic web application solutions.</li>
                        <li>Expertise in creating engaging and user-friendly mobile applications.</li>
                        <li>Innovative web designing that captures your brand’s essence and provides an intuitive user interface.<br/>Expertise in creating engaging and user-friendly mobile applications.</li>
                        <li>Commitment to delivering high-quality software projects and <br/> products that exceed our customers' expectations.</li>
                        <li>Collaborative approach to ensure your vision is realized through our solutions.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    
  );
};

export default AboutSection;