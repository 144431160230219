import React from 'react';
import mobile from '../assets/img/mbile.png';
import desk from '../assets/img/desk.png';
import rak from '../assets/img/rak.png';
import scl from '../assets/img/scl.png';

import downArrow from '../assets/img/down-ero.png'; // Import the image

const StatsSection = () => {
  return (
    <section className=" sec-7 py-4 mrg-tp mt-5">
    <div className="stats-container">
        <div className="stat-item">
            <img src={mobile} width="30%" alt="Logo"  className="bg-non mb-2"/>
            <h2 id="mobile-count">5+</h2>
            <p>Mobile Applications</p>
        </div>
        <div className="stat-item">
            <img src={desk} width="30%" alt="Logo"  className="bg-non mb-2"/>
            <h2 id="website-count">50+</h2>
            <p>Web Applications</p>
        </div>
        <div className="stat-item">
            <img src={rak} width="30%" alt="Logo"  className="bg-non mb-2"/>
            <h2 id="marketing-count">10+</h2>
            <p>Marketing & SEO</p>
        </div>
        <div className="stat-item">
            <img src={scl} width="30%" alt="Logo"  className="bg-non mb-2"/>
            <h2 id="design-count">100+</h2>
            <p>Graphic Designing</p>
        </div>
    </div>
    </section> 
    
  );
};

export default StatsSection;