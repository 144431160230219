import React from 'react';
import serviceImage from '../assets/img/shd-bg.png';
import downArrow from '../assets/img/down-ero.png'; // Import the image

const ServicesSection = () => {
  return (
    <section className="py-4 mrg-tp mt-5" id="services">
      <div className="container">
        <div className="col-md-12">
          <h1>UNVEILING</h1>
          <h1 className="mrg-95">POSSIBILITIES</h1>
        </div>
        <div className="row mt-5">
          <div className="col-md-6">
            <h2>What we do</h2>
            <span>Znh Solutions specializes in multiple types of services - from custom software development to team augmentation and body leasing. Our experienced team of experts will help you choose the right solution for your business.</span>
            <div className="img">
              <img src={serviceImage} width="90%" alt="Logo" className="mbl-tp" />
            </div>
          </div>
          <div className="col-md-6">

            <details>
                <summary>Web Application Development<img src={downArrow} alt="znhsolutions web development" className="bg-non" width="4%" /></summary>
                <p>We build scalable and efficient web applications to streamline business operations.</p>
            </details>
            <details>
              <summary>Mobile Application Development
                <img src={downArrow} className="bg-non" width="4%" alt="znhsolutions Mobile development" />
              </summary>
              <p>ZNH offers top-tier software development services, covering everything from ideation to security, support, and maintenance...</p>
            </details>

            <details>
                <summary>Graphic Designing<img src={downArrow} className="bg-non"  alt="znhsolutions graphic designing" width="4%" /></summary>
                <p>Our team creates visually appealing designs tailored to your brand identity.</p>
            </details>

            <details>
                <summary>Digital Marketing<img src={downArrow} className="bg-non"  alt="znhsolutions degital marketing" width="4%" /></summary>
                <p>Enhance your brand visibility with our expert digital marketing strategies.</p>
            </details>

            <details>
                <summary>Search Engine Optimization<img src={downArrow} className="bg-non"  alt="znhsolutions seo" width="4%" /></summary>
                <p>Improve your website ranking with our proven SEO techniques.</p>
            </details>

            <details>
                <summary>3D/2D Animation<img  src={downArrow} className="bg-non"  alt="znhsolutions annimation" width="4%" /></summary>
                <p>We create engaging animations to bring your ideas to life.</p>
            </details>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;