import React from 'react';
import ico from '../assets/img/ico-1.png';
import ico2 from '../assets/img/ico-2.png';
import ico3 from '../assets/img/ico-3.png';
import glo from '../assets/img/glo.png';

const WhyUsSections = () => {
  return (
    <section className=" sec-5 py-4 mrg-tp mt-5" id="whyus">
        <div className="container">
            <div className="row mt-5"> 
                <div className="col-md-6">
                    <h2>Why choose us?</h2>
                    <span>Discover the Znh Solutions difference: where innovation meets excellence. Our commitment to quality, expertise, and customer satisfaction sets us apart in the technology world.</span>
                    <span>Discover the Znh Solutions difference: where innovation meets excellence. Our commitment to quality, expertise, and customer satisfaction sets us apart in the technology world.At Znh Solutions, we bring a unique blend of expertise and passion to every project. Here’s why our clients choose us:</span>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-2">
                                <img src={ico} width="90%" alt="Logo"  className="ico"/>
                            </div>
                            <div className="col-md-10">
                                <b>Expert Team</b><br/>
                                <span>Our team of skilled professionals brings a wealth of knowledge and experience to every project.</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-2">
                                <img src={ico2} width="90%" alt="Logo"  className="ico" />
                            </div>
                            <div className="col-md-10">
                                <b>Tailored Solutions</b><br/>
                                <span>Our team of skilled professionals brings a wealth of knowledge and experience to every project.</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-2">
                                <img src={ico3} width="90%" alt="Logo"  className="ico"/>
                            </div>
                            <div className="col-md-10">
                                <b>Customer Satisfaction</b><br/>
                                <span>Our team of skilled professionals brings a wealth of knowledge and experience to every project.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <img src={glo} width="80%" alt="Logo"  className=" mt-5 mbl-tp" align="right"/>
                </div> 
            </div>
        </div>
    </section>
  );
};

export default WhyUsSections;