import React from 'react';
import logo from '../assets/img/logo-bk.png';

const Footer = () => {
  return (
    <footer className="footer-sec py-4 mrg-tp mt-2 ftr-img">
      <div className="container">
        <div className="row mt-5 ftr-dsp">
          <div className="footer-column">
            <img src={logo} alt="Logo" className="bg-non" width="80%" />
            <p>usto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
          </div>
            <div className="footer-column">
                <h3>Social</h3>
                <a href="https://www.facebook.com/people/ZNH-Solutions/100063472826298/">Facebook</a>
                <a href="https://www.instagram.com/znh.solutions/">Instagram</a>
                {/* <a href="#">Twitter</a> */}
            </div>
            <div className="footer-column">
                <h3>Services</h3>
                <a href="#">Web Development</a>
                <a href="#">Digital Marketing</a>
                <a href="#">App Development</a>
                <a href="#">2D/3D Animation</a>
                <a href="#">Graphic Designing</a>
            </div>
            <div className="footer-column">
                <h3>Contact us</h3>
                <p><strong>Address :</strong> <br/> Office #41 ,Khudad Heights E11 Islamabad</p>
                <p><strong>Phone :</strong> <br/>+92-345-789-7890</p>
                <p><strong>Email :</strong> <br/>info@zsolutions.com</p>
            </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;