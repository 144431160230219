import React from 'react';
import rak from '../assets/img/rak-img.png'; // Import the image

const BannerGetStarted = () => {
        return (
            <section className=" sec-6 py-4 mrg-tp mt-5">
                <div className="container">
                    <div className="bg-clr">
                        <div className="row">
                            <div className="col-md-6 mt-5">
                                <h2 className="m-3">Kickstart your </h2>
                                <h2 className="m-3">growth journey with </h2>
                                <h2 className="m-3">us now</h2>
                                <a href="#" className="btn btn-primary-new ms-3" align="left">Get Started</a>
                            </div>
                            <div className="col-md-6">
                                <img src={rak} width="70%" alt="Logo"  className="bg-non"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    };
export default BannerGetStarted;