import React from 'react';
import logo from '../assets/img/logo-bk.png';

const Header = () => {
  return (
    <header className="navbar navbar-expand-lg">
      <div className="container cont dis-med">
        <a className="navbar-brand" href="#">
          <img src={logo} alt="Logo" className="logo-med" width="80%" />
        </a>
        <button className="navbar-toggler tog" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item"><a className="nav-link active-nav" href="#">HOME</a></li>
            <li className="nav-item"><a className="nav-link" href="#services">SERVICES</a></li>
            <li className="nav-item"><a className="nav-link" href="#about_us">ABOUT US</a></li>
            <li className="nav-item"><a className="nav-link" href="#whyus">Why US</a></li>
            {/* <li className="nav-item"><a className="nav-link" href="#">PORTFOLIO</a></li> */}
            <li className="nav-item"><a className="nav-link" href="#contactus">CONTACT US</a></li>
          </ul>
          <a className="btn btn-primary ms-3" href="#contactus">Get Started</a>
        </div>
      </div>
    </header>
  );
};

export default Header;